'use strict';

$(
    function() {
        app.assignNumberControl();
        app.setSelectedLink();
        app.addRegisterCheckBoxes();
        app.attachSvgEvents();
        app.initACBL();
        app.setBackUrls();
        app.addCoverSpan();
        app.saveProductListInfo();
        app.topMenu.init();
        if (!app.isMobile()) {
            $(window).scroll(
                function() {
                    app.floatingHeader();
                }
            );
            app.floatingHeader();
        }
        app.assignProductOver();
        app.initializeScrollButton();
        app.hamburger.init({
            look: 1
        });
        app.color.init();
        app.extraMenu && app.extraMenu.init({
            side: 'right',
            selector: '.extramenu-hamb',
            saveState: false
        });
        app.template.collapse();
    }
);

var app = {
    _VERSION: 2,
    _vars: {
        v: '1.2',
        tmp: 28,
        _guid: "a64fc5e0-7253-4ed3-b026-2c6bb18411e6",
        putAttributesToProdItemsDirectly: true,
        _customFonts: {
            Linearicons: true
        }
    },
    responsiveMode: true,
    indexLinkPos: 0,
    messages: {},
    productAvailableQuantityUrl: null,
    optionsWereInitialized: false,
    fixTop: 150,
    template: {
        initSlider: function(params) {
            var {
                sliderHeight,
                sliderAutoplayTimeout,
                carouselAutoplayTimeout,
                leftMenu
            } = params || {};

            var generalResponsive = {
                0: {
                    items: 2
                },
                544: {
                    items: 3
                },
                761: {
                    items: 4
                },
                992: {
                    items: leftMenu !== 'Y' ? 5 : 4
                },
                1200: {
                    items: leftMenu !== 'Y' ? 6 : 5
                }
            };

            app.events.addListener('udu_OWL-INDEX-CAROUSEL-DONE', function(info) {
                heightCheck(info, 'category');
            });
            app.events.addListener('udu_AFTER-MAKE-CAROUSEL-DONE', function(info) {
                heightCheck(info);
            });

            app.owlSlider.makeBanner({
                speed: sliderAutoplayTimeout,
                animateIn: 'fadeIn',
                animateOut: 'fadeOut',
                setHeight: sliderHeight
            });
            app.owlSlider.makeCategoryCarousel({
                autoplay: false,
                dots: false,
                loop: false,
                margin: 60,
                minItemCount: 5,
                responsive: generalResponsive
            });
            app.owlSlider.makeProductListSlider({
                oneRow: true,
                margin: 0,
                minItemCount: 2,
                loop: false,
                controlScreenWidth: {
                    mobDestroy: 'N',
                    width: 991
                }
            });
            app.owlSlider.makeCarousel({
                autoHeight: true,
                autoplay: true,
                speed: carouselAutoplayTimeout,
                slideBy: 1,
                smartSpeed: 500,
                lazyLoad: true,
                responsive: generalResponsive
            });

            function heightCheck(info, section) {
                info || (info = {});
                section || (section = '');

                var data = info.data || '';
                if (!data) {
                    return;
                }

                var maxH = 0;
                var $slider = data.$slider;
                var $img = $('.owl-item img', $slider);
                var cntImg = $img.length;

                var $owlStageOuter = $('.owl-stage-outer', $slider);
                if (!$owlStageOuter.length) {
                    return;
                }

                var carTimer = setInterval(function() {
                    var gotheight = true;
                    var cntImgLoaded = 0;

                    $img.each(function() {
                        if (this.complete) {
                            cntImgLoaded++;
                        }
                    });

                    if ($owlStageOuter.height() < 2 || cntImg !== cntImgLoaded) {
                        gotheight = false;
                    }

                    if (gotheight) {
                        clearInterval(carTimer);
                        $img.each(function() {
                            var height = $(this).height();
                            if (height > maxH) {
                                maxH = height;
                            }

                            if (section === 'category') {
                                $('.owl-item .image-container', $slider).css('min-height', maxH);
                            } else {
                                $('.owl-item > .item', $slider).css('height', maxH);
                            }
                        });
                    }
                }, 300);
            }
        },
        collapse: function() {
            $(document).on('click', '[data-udu-toggle="collapse"]', function() {
                var target = $(this).siblings('.is-collapse');
                if ($(this).attr('aria-expanded') === 'false') {
                    $(target).slideDown().addClass('in');
                    $(this).removeClass('is-collapsed').attr('aria-expanded', 'true');
                } else {
                    $(target).slideUp().removeClass('in');
                    $(this).addClass('is-collapsed').attr('aria-expanded', 'false');
                }
            });
        }
    }
};